<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="clientsStore.client.d_nameLast + ', ' +
      clientsStore.client.d_nameFirst" />

      <!--Function Bar-->
      <client-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col cols="12" sm="12">
            <payments :paymentSection="3"/>
          </v-col>
        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ClientFunctionBar from '@/components/ClientFunctionBar';
import Payments from '@/components/Payments';

export default {
  name: 'ClientPayments',
  mixins: [mixin],
  computed: {
    ...mapState({
      clientsStore: (state) => state.clientsStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
    ClientFunctionBar,
    Payments,
  },
  created() {
    this.initialize();
  },
  data() {
    return {
	    progress: true,
    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
      this.$store.dispatch('progressStore/set', false);
	  },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
